<template>
  <div class="baseInfo">
    <div class="info-image">
      <upload-img :selectImg.sync="form.img"></upload-img>
    </div>
    <div class="info-content">
      <el-descriptions :column="2" border>
        <el-descriptions-item label="总包部名称">
          <el-input v-model="form.name"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="设立时间">
          <div class="date">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="form.found_time"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="总包部管理员">
          <el-input v-model="form.people_name"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="联系电话">
          <el-input v-model="form.phone_number"></el-input>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-button class="subBtn" @click="submit">提交</el-button>
  </div>
</template>

<script>
import { isPhoneNum } from "@/utils/validate";
import UploadImg from '@/components/UploadImg'
import {addInformation} from '@/api';
export default {
  name: "supervision",
  components: {
    UploadImg
  },
  methods: {
    filterData(){
      let data = {}
      Object.keys(this.form).forEach(item => {
        data[item] = this.form[item];
      })
      return data;
    },
    async submit(){
      try{
        if(!isPhoneNum(this.form.phone_number)) {
          throw new Error("手机格式错误")
        }
        const requestData = {
          type:1,
          value:this.filterData()
        }
        const res = await addInformation(requestData);
        if(res.status == 200) {
          this.$success("添加成功")
        }
      }catch (e) {
        if(e instanceof Error){
          this.$error(e.message)
        }
      }
    }
  },
  data() {
    return {
      form: {
        img:"",
        name:"",
        found_time:"",
        people_name:"",
        phone_number:""
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.baseInfo {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-content {
    margin-top: 30px;
    width: 100%;
    .option-content {
      display: flex;
      justify-content: center;
      height: 60px;
      .el-input {
        width: 100%;
      }
    }
  }
  .subBtn{
    margin-top: 30px;
    padding: 10px 100px;
    background-color: red;
    border-radius: 5px;
    color: #fff;
  }
}
::v-deep .el-descriptions-item__label {
  background-color: darkgray;
  width: 200px;
  height: 30px !important;
  font-weight: 100;
  color: black;
}

::v-deep .el-input__inner{
  border: none;
  padding: 0;
  height: 20px;
}
::v-deep .el-icon-time{
  content:""
}
</style>
